.connect {
  padding-top: 100px;
}

.connect h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 64px;
  text-transform: uppercase;
  text-align: center;
  color: #000000;
  margin-bottom: 70px;
}

.connect-main {
  display: flex;
  justify-content: space-around;
}

.connect h2 span {
  color: #01aaec;
}

.connect-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.connect-img {
  padding: 30px;
  background: #eaf3f8;
  box-shadow: -15px 18px 30px rgba(81, 94, 90, 0.26);
  border-radius: 10px;
  z-index: 1;
  position: relative;
}
.connect-block a {
  display: block;
  margin: 50px auto;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 10px 30px;
  text-transform: uppercase;
  color: #ffffff;
  background: #01aaec;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: 0.4s;
}

.connect-block a:hover {
  transition: 0.4s;
  color: #171c20;
}

.connect-img::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  left: 0;
  background: linear-gradient(193.26deg, #029ff6 2.86%, #054163 99.07%);
  z-index: -1;
  transition: opacity 0.5s linear;
  opacity: 0;
}

.connect-img:hover::before {
  opacity: 1;
}

.connect-img svg path {
  transition: 0.5s;
}

.connect-img:hover svg path {
  transition: 0.5s;
  fill: #ffffff;
}

@media (max-width: 991.98px) {
  .connect-main {
    flex-wrap: wrap;
  }
  .connect-block {
    margin: 0 10px;
  }
}

@media (max-width: 767.98px) {
  .connect h2 {
    font-family: HelveticaNeueCyr;
    font-size: 48px;
    line-height: 64px;
  }
}

@media (max-width: 575.98px) {
  .connect h2 {
    font-size: 38px;
  }
}

@media (max-width: 575.98px) {
  .connect svg {
    width: 70px;
    height: 70px;
  }
  .connect-block {
    margin: 0;
  }
  .connect h2 {
    font-size: 30px;
  }
  .connect-block a {
    font-size: 16px;
  }
}
