.main-platform {
  display: flex;
}

.platform {
  margin-top: 200px;
}

.left-platform {
  width: 40%;
  padding-top: 130px;
}

.right-platform {
  width: 60%;
}

.platform h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 70px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #01aaec;
  display: inline;
}

.platform .platfrom-title {
  padding: 20px;
  border: 5px solid #01aaec;
}
.platform h2 span {
  color: #202020;
}

.left-platform button {
  padding: 9px 30px;
  background: #01aaec;
  border-radius: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 60px auto 0;
  border: none;
  display: block;
  transition: 0.4s;
  cursor: pointer;
}

.left-platform button:hover {
  transition: 0.4s;
  color: #202020;
}

.right-platform {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 0 0 30px;
}
.block-platform {
  width: 44%;
  background: #eaf3f8;
  box-shadow: -30px 35px 30px rgba(81, 94, 90, 0.26);
  border-radius: 8px;
  margin-bottom: 40px;
  padding: 40px 20px 20px 20px;
  transition: 0.5s;
  z-index: 1;
  position: relative;
}

.block-platform h6 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  text-transform: capitalize;
  color: #202020;
  margin: 30px 0;
}

.block-platform p {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #010101;
}

.block-platform:hover {
  transition: 0.5s;
  box-shadow: -30px 35px 30px 0px #3b95ff42;
}
.block-platform::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  left: 0;
  background: linear-gradient(193.26deg, #029ff6 2.86%, #054163 99.07%);
  z-index: -1;
  transition: opacity 0.5s linear;
  opacity: 0;
}

.block-platform:hover::before {
  opacity: 1;
}

.block-platform:hover p,
.block-platform:hover h6 {
  transition: 0.5s;
  color: #fff;
}

.block-platform svg path {
  transition: 0.5s;
}

.block-platform:hover svg path {
  transition: 0.5s;
  fill: #fff;
}

.left-platform .block-platform {
  width: 83%;
  margin: 30px auto;
  display: none;
}

.left-platform .slick-dots {
  bottom: -95px;
}

.platform .slick-dots li {
  margin: 0 10px;
}

.platform .slick-dots li button:before {
  font-size: 24px;
}

.platform .slick-dots li.slick-active button:before {
  color: #01aaec;
}

@media (max-width: 1199.98px) {
  .main-ankerplatform {
    margin-top: 1400px;
  }
}

@media (max-width: 991.98px) {
  .right-platform {
    display: none;
  }
  .left-platform {
    width: 100%;
    padding: 0;
  }

  .left-platform .block-platform {
    display: block;
    height: 350px;
    margin: 30px auto 70px;
  }

  .platform .platfrom-title {
    margin-bottom: 40px;
  }

  .left-platform button {
    margin: 70px auto 0;
  }
}

@media (max-width: 767.98px) {
  .platform h2 {
    font-size: 36px;
    line-height: 50px;
  }

  .left-platform .block-platform {
    height: auto;
    margin: 30px auto 75px;
    width: 78%;
  }
}

@media (max-width: 575.98px) {
  .platform h2 {
    font-size: 28px;
    line-height: 40px;
  }
}
