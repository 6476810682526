.video {
  margin-bottom: 100px;
}
.main-video {
  width: 95%;
  margin: 0 auto;
}

.video-slide {
  padding: 20px;
  margin: 20px auto;
}

.slick-slide {
  transition: 0.5s;
}
.slick-slide.slick-center {
  transition: 0.5s;
  transform: scale(1.2);
}

.video-slide iframe {
  box-shadow: -10px 15px 10px 0px #515e5a42;
}

.main-video .slick-dots li button:before {
  font-size: 22px;
}

.main-video .slick-dots li.slick-active button:before {
  color: #01aaec;
}

@media (max-width: 767.98px) {
  .main-video {
    width: 90%;
  }

  .video-slide {
    padding-left: 15%;
    padding-right: 15%;
  }
}
