.ankerplatform-scheme .container {
  overflow-x: scroll;
}

.main-ankerplatform-scheme {
  margin-top: 100px;
  position: relative;
  height: 690px;
  width: 1140px;
}

.ankerplatform-scheme .container::-webkit-scrollbar {
  width: 0;
}

.ankerplatform-scheme-block {
  width: 105px;
  height: 105px;
  background: #eaf3f8;
  box-shadow: -15px 18px 30px rgba(81, 94, 90, 0.26);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: absolute;
  top: 20px;
  left: 160px;
  text-align: center;
}

.ankerplatform-scheme-block span {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.ankerplatform-scheme-block:nth-child(3) {
  left: 435px;
}

.ankerplatform-scheme-block:nth-child(4) {
  left: 820px;
}
.ankerplatform-scheme-block:nth-child(5) {
  top: 480px;
  left: 435px;
}
