.main-community-center .community-left {
  width: 40%;
}
.main-community-center .community-right {
  width: 60%;
}

.main-community-center .community-column {
  display: flex;
}

.community-column {
  margin-top: 100px;
}

.community-left h3 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 64px;
  text-align: right;
  text-transform: uppercase;
  color: #000000;
  border: 5px solid #01aaec;
  padding: 40px 15px;
}

.community-left h3 span {
  color: #01aaec;
}

.community-block {
  display: flex;
  background: #eaf3f8;
  box-shadow: -30px 35px 30px rgba(81, 94, 90, 0.26);
  border-radius: 10px;
  padding: 35px 40px;
  width: 105%;
  margin-left: -5%;
  margin-top: 15%;
}

.community-text {
  max-width: 75%;
}
.community-text h5 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  color: #010101;
}

.community-text p {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  margin: 30px 0;
  color: #010101;
  padding-right: 40px;
}

.community-left p {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #010101;
  margin: 50px 0;
}

.community-left p span {
  display: block;
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #01aaec;
  margin-top: 50px;
}

.community-right button {
  display: block;
  margin: 0 auto;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 10px 30px;
  text-transform: uppercase;
  color: #ffffff;
  background: #01aaec;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: 0.4s;
}

.community-right button:hover {
  transition: 0.4s;
  color: #171c20;
}

@media (max-width: 1199.98px) {
  .community-left h3 {
    font-size: 54px;
  }
}

@media (max-width: 991.98px) {
  .community-left h3 {
    display: inline-block;
  }
  .community-block {
    width: 95%;
    margin-left: 5%;
    margin-top: -5%;
  }
  .community-column {
    flex-wrap: wrap;
  }
  .main-community-center .community-left {
    width: 90%;
  }
  .main-community-center .community-right {
    width: 100%;
  }
  .community-left p {
    margin: 50px 0 0;
  }

  .main-community-center .community-column:nth-child(2) .community-left {
    width: 100%;
  }
  .main-community-center .community-column:nth-child(2) h3 {
    display: block;
  }
}

@media (max-width: 767.98px) {
  .community-block {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .community-text p {
    padding-right: 0;
  }
  .community-text {
    max-width: 100%;
    margin-top: 20px;
  }
  .community-block svg {
    height: auto;
    max-width: 50%;
  }
}

@media (max-width: 399.98px) {
  .community-left h3 {
    font-size: 38px;
    line-height: 45px;
  }
  .community-block {
    padding: 20px;
  }
  .community-block p {
    font-size: 20px;
  }
}
