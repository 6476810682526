.growing {
  padding-bottom: 50px;
}



.main-grn {
  display: flex;
}

.left-grn,
.right-grn {
  width: 50%;
}


.right-grn img {
    box-shadow: -30px 35px 30px 0 rgba(0,0,0,.25098039215686274);

}

.left-grn  .box {
    margin-right: 100px;

}

.right-grn  .box {
    margin-left: 100px;

}

.main-grn img {

  margin: 100px 0;
}
.main-grn .box {
  border: 5px solid #01aaec;
  padding: 20px 10px;
  padding-left: 10px;
  
  text-align: right;
  padding-left: 100px;

}
.main-grn h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  text-transform: uppercase;
  color: #000000;
  padding-bottom: 20px;
}

.main-grn h2 span {
  color: #01aaec;
}

.main-growing h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 62px;
  line-height: 62px;
  text-transform: uppercase;
  color: #000000;
  margin: 100px 0;
  border: 5px solid #01aaec;
  padding: 20px 10px;
  text-align: center;
}

.main-growing h2 span {
  color: #01aaec;
}

.growing-block {
  background: #eaf3f8;
  box-shadow: -10px 15px 10px rgba(81, 94, 90, 0.26);
  border-radius: 10px;

  max-width: 85%;
  align-items: center;
  text-align: center;
  margin: 0 auto 50px;
  transition: 0.7s;
  position: relative;
  z-index: 1;
}
.growing-block::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  left: 0;
  background: linear-gradient(193.26deg, #029ff6 2.86%, #054163 99.07%);
  z-index: -1;
  transition: opacity 0.5s linear;
  opacity: 0;
}

.growing-block:hover::before {
  opacity: 1;
}
.growing-block:hover span {
  transition: 0.5s;
  color: #fff;
}

.growing-block span {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #010101;
  margin: 20px 0;
  transition: 0.5s;
}

.growing-block > a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.growing-block a a {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #01aaec;
  margin: 30px 0;
}

.growing .slick-dots li button:before {
  font-size: 22px;
}

.growing .slick-dots li.slick-active button:before {
  color: #01aaec;
}

@media (max-width: 767.98px) {
  .main-grn { flex-direction: column;}
  .main-grn .box {
    margin: 0 !important;
    padding: 8px !important;
  }
  .left-grn,
  .right-grn {
    width: 100%;
  }

}

@media (max-width: 575.98px) {
  .main-growing h2 {
    font-size: 48px;
  }
}

@media (max-width: 379.98px) {
  .main-growing h2 {
    font-size: 40px;
  }
}
