.main-locations {
  padding-top: 100px;
  text-align: center;
}

.main-locations h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  align-items: center;
  text-transform: uppercase;
  color: #202020;
  border: 5px solid #01aaec;
  padding: 20px 30px;
  display: inline-block;
}

.main-locations span {
  color: #01aaec;
}

.main-locations .subtitle {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #01aaec;
  position: relative;
  justify-content: center;
  margin: 40px auto 50px;
}

.main-locations .subtitle .subtitle-icon {
  background: #eaf3f8;
  box-shadow: -30px 35px 30px rgb(81 94 90 / 26%);
  border-radius: 4px;
  position: relative;
  height: 100px;
  width: 340px;
  text-align: left;
  margin-top: 20px;
  margin-left: 20px;
  padding-left: 60px;
}

.main-locations .subtitle-icon::before {
  content: "";
  top: 0;
  position: absolute;
  height: 80%;
  left: 8%;
  width: 4px;
  background-color: #01aaec;
}

.main-locations .subtitle-icon span {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-transform: capitalize;
  color: #202020;
  display: inline-block;
  margin-top: 25px;
}

.main-locations .subtitle-icon img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 7%;
  z-index: 2;
}

.network-block {
  background: #eaf3f8;
  box-shadow: -30px 35px 30px rgba(81, 94, 90, 0.26);
  border-radius: 10px;
  padding: 20px;
  max-width: 230px;
  position: relative;
  z-index: 1;
  margin: 20px 0 50px;
}

.network-block a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.network-block a span {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 132.19%;
  text-align: center;
  color: #010101;
  transition: 0.5s;
  margin-top: 30px;
}
.network-block::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  left: 0;
  background: linear-gradient(193.26deg, #029ff6 2.86%, #054163 99.07%);
  z-index: -1;
  transition: opacity 0.5s linear;
  opacity: 0;
}

.network-block:hover::before {
  opacity: 1;
}

.network-block:hover a span {
  transition: 0.5s;
  color: #fff;
}

.main-locations .slider {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.main-locations .slick-dots li button:before {
  font-size: 22px;
}

.main-locations .slick-dots li.slick-active button:before {
  color: #01aaec;
}

@media (max-width: 991.98px) {
  .network-slide {
    margin: 0 50px;
  }
}

@media (max-width: 767.98px) {
  .network-slide {
    margin: auto;
  }
  .network-block {
    margin: 85px auto;
  }

  .main-locations h2 {
    font-size: 36px;
    line-height: 36px;
  }

  .main-locations .subtitle {
    flex-wrap: wrap;
  }

  .main-locations .subtitle {
    font-size: 36px;
    line-height: 36px;
  }
}

@media (max-width: 399.98px) {
  .network-block {
    max-width: 80%;
  }
  .network-block a span {
    font-size: 16px;
  }
}
