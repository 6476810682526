.faq {
  margin-top: 100px;
}
.main-faq {
  margin-bottom: 120px;
}

.faq h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 64px;
  text-transform: uppercase;
  color: #000000;
  text-align: center;
}

.faq h6 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  color: #01aaec;
  margin-top: 10px;
  margin-bottom: 80px;
}

.faq .collapse {
  width: 100%;
  margin: 25px 0;
}

.faq .collapse .collapse-title {
  font-size: 20px;
}
