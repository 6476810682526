.download {
  background: url("../../../img/community-bg.png") no-repeat center;
  background-size: cover;
}

.download .left h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
}

.download .left h4 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  color: #ffffff;
}

.download .left h4 span {
  color: #202020;
}

.main-download {
  display: flex;
}

.main-download .left,
.main-download .right {
  width: 50%;
}

.downloads-block {
  display: flex;
}

.downloads-block a {
  border: 1px solid #a6a6a6;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.4s;
  width: 140px;
  margin-right: 40px;
}

.downloads-block a:hover {
  box-shadow: 0px 0px 14px 0px #ffffffc2;
  transition: 0.4s;
}

.main-download .left {
  padding: 30px;
}

.main-download .right {
  padding: 30px;
}
.main-download .left {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.main-download .right img {
  margin: 0 auto;
}

@media (max-width: 991.98px) {
  .main-download {
    flex-wrap: wrap;
  }
  .main-download .left,
  .main-download .right {
    width: 100%;
  }

  .download {
    background: url("../../../img/download-mobile-bg.png") no-repeat center;
    background-size: cover;
  }

  .main-download .left {
    text-align: center;
  }

  .download .left h2 {
    justify-content: center;
    margin: 40px auto;
  }

  .downloads-block {
    margin: 40px auto;
    width: 100%;
    justify-content: space-around;
  }

  .downloads-block a {
    margin: 0 auto;
  }
}

@media (max-width: 767.98px) {
  .download .left h2 {
    font-size: 36px;
  }
  .download .left h4 {
    font-size: 24px;
  }
}

@media (max-width: 499.98px) {
  .download .left h2 {
    margin: 40px auto 10px;
  }

  .main-download .left {
    padding: 0;
  }

  .downloads-block {
    margin: 40px auto 100px;
  }
  .main-download .right {
    padding: 0 0 60px 0;
  }
}

@media (max-width: 380.98px) {
  .main-download .right {
    padding: 0 0 120px 0;
  }
}
