.main-features {
  display: flex;
  margin-top: 100px;
}

.left-features {
  width: 40%;
}

.right-features {
  width: 60%;
}

.left-features h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 64px;
  display: flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #202020;
  padding: 30px;
  border: 5px solid #01aaec;
}

.left-features p {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 132.19%;
  text-align: right;
  color: #010101;
  margin: 50px 0;
}

.right-features {
}

.features-block {
  display: flex;

  align-items: flex-start;
}

.features-block .number {
  background: #eaf3f8;
  box-shadow: -15px 18px 10px rgba(81, 94, 90, 0.26);
  border-radius: 10px;
  min-width: 138px !important;
  width: 138px !important;
  height: 138px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 50px 50px;
}

.features-block .number span {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 64px;
  align-items: center;
  text-transform: uppercase;
  color: #01aaec;
}

.features-block .text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.features-block .text h6 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  text-align: left;
  color: #161616;
  margin-bottom: 20px;
}

.features-block .text p {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #010101;
}

.bottom-features {
  display: flex;
  width: 80%;
  justify-content: center;
  margin: 50px auto;
}

.bottom-features div {
  background: #00658c;
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
  padding: 10px 30px;
}

.bottom-features div span {
  font-weight: bold;
}

.bottom-features a {
  display: block;
  margin: 0 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  line-height: 26px;
  padding: 10px 30px;
  text-transform: uppercase;
  color: #ffffff;
  background: #01aaec;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: 0.4s;
}

.bottom-features a:hover {
  transition: 0.4s;
  color: #171c20;
}

@media (max-width: 991.98px) {
  .main-features {
    flex-direction: column;
  }
  .bottom-features {
    width: 100%;
  }
  .left-features,
  .right-features {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .features-block {
    display: flex;
    align-items: center;
    margin: 30px 0;
  }
  .features-block .number {
    margin: 0 20px;
  }

  .bottom-features {
    display: none;
  }
  .left-features h2 {
    font-size: 36px;
  }
  .features-block .text p {
    font-size: 18px;
  }
}

@media (max-width: 399.98px) {
  .features-block .text p {
    font-size: 16px;
  }
  .features-block .number {
    margin: 0px 10px;
    min-width: 100px !important;
    width: 100px !important;
    height: 100px !important;
  }
}
