.menu ul {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.menu ul li {
  list-style: none;
  margin: 0 15px;
}

.menu ul li a {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  transition: 0.5s;
}

.menu a:hover {
  color: #01aaec;
  transition: 0.5s;
}

.menu li:nth-child(1) a:hover,
.menu li:nth-child(1) a.active,
.menu li:nth-child(2) a:hover,
.menu li:nth-child(3) a:hover {
  color: #101010;
}

.menu li:nth-child(4) a:hover {
  color: #cccccc;
}

header.home {
  background: url("../../../img/header-bg.png") no-repeat center;
  background-size: cover;
  min-height: 100vh;
  padding-bottom: 130px;
}

.home .logo {
  display: flex;
  justify-content: center;
  margin-top: 90px;
}

.home h1 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 64px;
  color: #111a1f;
  text-align: center;
  margin-left: -60px;
  margin-top: 30px;
}

.home h1 span {
  color: #01aaec;
}

header.home h5 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-top: 50px;
}

.downloads-buttons {
  margin-top: 160px;
  display: flex;
  justify-content: space-around;
}

.downloads-buttons a {
  border: 1px solid #a6a6a6;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.4s;
}

.downloads-buttons a:hover {
  box-shadow: 0px 0px 14px 0px #ffffffc2;
  transition: 0.4s;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 46px;
  height: 30px;
  right: 36px;
  top: 36px;
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #01aaec;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #044366;
}

.bm-burger-bars:nth-child(3) {
  display: none;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #e3e3e3;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

.bm-item li {
  margin: 10px 0;
}

.bm-item a {
  color: #111;
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  text-transform: uppercase;
  color: #202020;
}

.bm-item a.active {
  color: #01aaec;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media (max-width: 991.98px) {
  .bm-burger-button {
    display: block;
  }

  header.home .menu {
    display: none;
  }

  .home .logo {
    margin-top: 0;
    padding-top: 30%;
  }

  .downloads-buttons {
    flex-wrap: wrap;
  }

  .downloads-buttons a {
    margin-bottom: 30px;
  }

  .home h1 {
    font-size: 52px;
    line-height: 64px;
    margin-left: -48px;
  }
}

@media (max-width: 767.98px) {
  .home h1 {
    font-size: 36px;
    line-height: 64px;
    margin-left: -33px;
  }
}

@media (max-width: 575.98px) {
  .downloads-buttons a {
    max-width: 44%;
  }
}

@media (max-width: 429.98px) {
  .home h1 {
    font-size: 32px;
    margin-left: -25px;
  }
}

@media (max-width: 369.98px) {
  .home h1 {
    font-size: 27px;
    margin-left: -19px;
  }
}
