.main-ecosystem {
  margin-top: 200px;
  display: flex;
}

.left-ecosystem,
.right-ecosystem {
  width: 50%;
}

.left-ecosystem {
  text-align: left;
}

.left-ecosystem h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  align-items: left;
  text-transform: uppercase;
  color: #01aaec;
  margin-bottom: 80px;
  padding: 0 40px;
}

.left-ecosystem h2 span {
  color: #202020;
}

.right-ecosystem {
  border-left: 4px solid #01aaec;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ecosystem-block {
  background: #eaf3f8;
  box-shadow: -30px 35px 30px rgba(81, 94, 90, 0.26);
  border-radius: 4px;
  position: relative;
  height: 120px;
  width: 340px;
  margin-top: 20px;
}

.ecosystem-block a {
  display: block;
  width: 100%;
  height: 100%;
}

.ecosystem-block span {
  max-width: 50%;
  position: absolute;
  bottom: 20%;
  left: 15%;
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  color: #202020;
  transition: 0.4s;
}

.ecosystem-block::before {
  content: "";
  top: 0;
  position: absolute;
  height: 80%;
  left: 8%;
  width: 4px;
  background-color: #01aaec;
}

.ecosystem-block img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 7%;
  z-index: 2;
}

.ecosystem-block .hover-img {
  transition: 0.4s;
  opacity: 0;
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  right: 3%;
  width: auto;
  z-index: 1;
}

.ecosystem-block:hover span {
  transition: 0.4s;
  color: #01aaec;
}

.ecosystem-block:hover .hover-img {
  transition: 0.4s;
  opacity: 1;
}

@media (max-width: 991.98px) {
  .left-ecosystem,
  .right-ecosystem {
    width: 100%;
  }

  .main-ecosystem {
    margin-top: 100px;
    flex-wrap: wrap;
  }

  .left-ecosystem {
    text-align: center;
  }
  .left-ecosystem img {
    margin: 0 auto;
  }

  .right-ecosystem {
    border: none;
  }
  .ecosystem-block {
    width: 90%;
  }
}

@media (max-width: 575.98px) {
  .ecosystem h2 {
    font-size: 38px;
    padding: 0;
  }
}
