.main-solutions {
  margin-top: 100px;
}

.main-solutions h4 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  margin: 50px 0;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
}

.main-solutions h4 span {
  color: #01aaec;
}

.solutions-block {
  width: 29%;
  margin: 40px 20px 40px;
}

.main-solutions .regular.slider {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.solutions-block .img {
  background: #eaf3f8;
  box-shadow: -15px 18px 30px rgba(81, 94, 90, 0.26);
  border-radius: 10px;
  padding: 10px;
  display: inline-block;
  margin-bottom: 20px;
}

.solutions-block h5 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 132.19%;
  color: #000;
  margin: 0px 0 10px;
  min-height: 61px;
}

.solutions-block h5 span {
  color: #01aaec;
}

.solutions-block p {
  border-top: 1px solid #010101;
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #010101;
  padding: 20px 0;
}

.main-solutions .slick-dots {
  bottom: 25px;
}

.main-solutions .slick-dots li {
  margin: 0;
}

.main-solutions .slick-dots li button:before {
  font-size: 80px;
  content: "-";
  border: 0;
}

.main-solutions .slick-dots li.slick-active button:before {
  color: #01aaec;
}

@media (max-width: 767.98px) {
  .main-solutions h4 {
    font-size: 24px;
    line-height: 28px;
  }

  .solutions-block p {
    display: block;
    width: 90%;
  }
  .solutions-block .img {
    box-shadow: -6px 10px 18px rgb(81 94 90 / 26%);
  }
  .solutions-block {
    margin: 20px;
  }
}
