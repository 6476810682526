.ankerplatform-main {
}

.ankerplatform-main-title {
  padding: 200px 0 150px;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background: url("../../../img/ankerplatform-bg.png") no-repeat center;
}

.ankerplatform-main h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 64px;
  text-transform: uppercase;
  color: #000;
  background-color: #ffffff;
  border: 5px solid #01aaec;
  padding: 10px 30px;
  margin: 0 auto;
}

.ankerplatform-main h2 span {
  color: #01aaec;
}

.ankerplatform-main-title button {
  margin: 0 30px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 19px 40px;
  text-transform: uppercase;
  color: #ffffff;
  background: #01aaec;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: 0.4s;
  margin-top: 100px;
}

.ankerplatform-main-title button:hover {
  transition: 0.4s;
  color: #171c20;
}

.explained {
  display: flex;
}

.explained .left-explained {
  width: 40%;
  padding-right: 20px;
}

.explained .right-explained {
  width: 60%;
}

.explained .left-explained h3 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  border: 5px solid #01aaec;
  text-transform: uppercase;
  color: #000;
  padding: 40px 30px;
  margin-right: 40px;
}

.explained .left-explained h3 span {
  color: #01aaec;
}

.explained .left-explained p {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 132.19%;
  color: #010101;
  margin: 30px 0;
}

.explained .left-explained button {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 10px 40px;
  text-transform: uppercase;
  color: #ffffff;
  background: #01aaec;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: 0.4s;
}

.explained .left-explained button:hover {
  transition: 0.4s;
  color: #171c20;
}

.explained .explained-block {
  background: #eaf3f8;
  box-shadow: -30px 35px 30px rgba(81, 94, 90, 0.26);
  border-radius: 10px;
  margin: 0 30px 30px 30px;
  padding: 30px;
}

.explained-block h6 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #010101;
  margin: 15px 0;
}

.explained-block p {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #010101;
}

@media (max-width: 991.98px) {
  .explained {
    flex-wrap: wrap;
  }
  .explained .left-explained,
  .explained .right-explained {
    width: 100%;
  }
  .explained .explained-block {
    margin: 50px 0;
  }
}

@media (max-width: 767.98px) {
  .ankerplatform-main-title {
    font-size: 52px;
    background: url("../../../img/ankerplatform-mobile-bg.png") no-repeat center;
  }
  .ankerplatform-main h2 {
    font-size: 60px;
  }
}

@media (max-width: 419.98px) {
  .ankerplatform-main-title {
    font-size: 52px;
  }

  .main-header-title h1 {
    font-size: 21px;
  }

  .explained .left-explained h3 {
    font-size: 38px;
    margin-right: 0;
    padding: 30px 20px;
  }

  .ankerplatform-main h2 {
    font-size: 38px;
    padding: 10px 20px;
  }
}
