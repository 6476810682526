.timeline {
  margin-top: 150px;
}
.timeline h2 {
  text-align: center;
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  text-transform: uppercase;
  color: #202020;
}

.timeline .mobile-timline-title {
  position: absolute;
  display: none;
  padding: 0;
  margin: 0;
  visibility: visible;
}
.timeline h2 span {
  color: #01aaec;
}
.main-timeline {
  position: relative;
}

.main-svg {
  position: absolute;
  top: 60px;
  left: 0;
}

.mobile-svg {
  display: none;
}

.timeline-block {
  background: #eaf3f8;
  box-shadow: -15px 18px 30px rgba(81, 94, 90, 0.26);
  border-radius: 10px;
  width: 149px;
  height: 149px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 200px;
  left: 165px;
}

.timeline-block p {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #202020;
}

.timeline-block span {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  text-transform: capitalize;
  color: #000000;
  position: absolute;
  bottom: -60px;
  white-space: nowrap;
}

.timeline-block:nth-child(2) {
  top: 200px;
  left: 505px;
}

.timeline-block:nth-child(3) {
  top: 200px;
  left: 850px;
}
.timeline-block:nth-child(4) {
  top: 450px;
  left: 165px;
}

.timeline-block:nth-child(5) {
  top: 450px;
  left: 505px;
}
.timeline-block:nth-child(6) {
  top: 450px;
  left: 850px;
}

.timeline-block:nth-child(7) {
  top: 700px;
  left: 165px;
}

.timeline-block:nth-child(8) {
  top: 700px;
  left: 505px;
}
.timeline-block:nth-child(9) {
  top: 700px;
  left: 850px;
}

.timeline-block:nth-child(10) {
  top: 1005px;
  left: 165px;
}

.timeline-block:nth-child(11) {
  top: 1005px;
  left: 505px;
}
.timeline-block:nth-child(12) {
  top: 1005px;
  left: 850px;
}

.timeline-block:nth-child(13) {
  top: 1305px;
  left: 165px;
}

@media (max-width: 1199.98px) {
  .main-timeline {
    margin-top: -100px;
  }

  .timeline h2 {
    padding-top: 100px;
  }
  .timeline-block {
    width: 125px;
    height: 125px;
    top: 280px;
    left: 135px;
  }

  .timeline-block:nth-child(2) {
    top: 280px;
    left: 425px;
  }

  .timeline-block:nth-child(3) {
    top: 280px;
    left: 720px;
  }
  .timeline-block:nth-child(4) {
    top: 495px;
    left: 135px;
  }

  .timeline-block:nth-child(5) {
    top: 495px;
    left: 425px;
  }
  .timeline-block:nth-child(6) {
    top: 495px;
    left: 720px;
  }

  .timeline-block:nth-child(7) {
    top: 710px;
    left: 135px;
  }

  .timeline-block:nth-child(8) {
    top: 710px;
    left: 425px;
  }
  .timeline-block:nth-child(9) {
    top: 710px;
    left: 720px;
  }

  .timeline-block:nth-child(10) {
    top: 975px;
    left: 135px;
  }

  .timeline-block:nth-child(11) {
    top: 975px;
    left: 425px;
  }
  .timeline-block:nth-child(12) {
    top: 975px;
    left: 720px;
  }

  .timeline-block:nth-child(13) {
    top: 1230px;
    left: 135px;
  }

  .timeline-block span {
    font-size: 20px;
  }
}

@media (max-width: 991.98px) {
  .main-timeline {
    margin-top: -200px;
  }

  .timeline h2 {
    padding-top: 250px;
  }
  .timeline-block {
    width: 100px;
    height: 100px;
    top: 395px;
    left: 100px;
  }

  .timeline-block:nth-child(2) {
    top: 395px;
    left: 320px;
  }

  .timeline-block:nth-child(3) {
    top: 395px;
    left: 540px;
  }
  .timeline-block:nth-child(4) {
    top: 557px;
    left: 100px;
  }

  .timeline-block:nth-child(5) {
    top: 557px;
    left: 320px;
  }
  .timeline-block:nth-child(6) {
    top: 557px;
    left: 540px;
  }

  .timeline-block:nth-child(7) {
    top: 720px;
    left: 100px;
  }

  .timeline-block:nth-child(8) {
    top: 720px;
    left: 320px;
  }
  .timeline-block:nth-child(9) {
    top: 720px;
    left: 540px;
  }

  .timeline-block:nth-child(10) {
    top: 915px;
    left: 100px;
  }

  .timeline-block:nth-child(11) {
    top: 915px;
    left: 320px;
  }
  .timeline-block:nth-child(12) {
    top: 915px;
    left: 540px;
  }

  .timeline-block:nth-child(13) {
    top: 1108px;
    left: 100px;
  }

  .timeline-block span {
    font-size: 16px;
    bottom: -40px;
  }

  .timeline-block p {
    font-size: 16px;
  }
}

@media (max-width: 767.98px) {
  .main-timeline {
    text-align: center;
  }

  .timeline-block::before {
    content: "";
    position: absolute;
    width: 110px;
    height: 110px;
    border: 3px solid #01aaec;
    z-index: -1;
    border-radius: 10px;
    top: 10%;
    right: 10%;
    background-color: #fff;
  }
  .main-svg {
    display: none;
  }
  .mobile-svg {
    display: block;
    position: absolute;
  }

  .timeline-block span {
    bottom: -85px;
    white-space: normal;
  }
  .timeline-block {
    width: 110px;
    height: 110px;
    top: 500px;
    left: 100px;
  }
  .timeline-block:nth-child(2) {
    top: 500px;
    left: 340px;
  }

  .timeline-block:nth-child(3) {
    top: 745px;
    left: 100px;
  }
  .timeline-block:nth-child(4) {
    top: 745px;
    left: 340px;
  }

  .timeline-block:nth-child(5) {
    top: 1005px;
    left: 100px;
  }
  .timeline-block:nth-child(6) {
    top: 1005px;
    left: 340px;
  }

  .timeline-block:nth-child(7) {
    top: 1263px;
    left: 100px;
  }

  .timeline-block:nth-child(8) {
    top: 1263px;
    left: 340px;
  }
  .timeline-block:nth-child(9) {
    top: 1520px;
    left: 100px;
  }

  .timeline-block:nth-child(10) {
    top: 1520px;
    left: 340px;
  }

  .timeline-block:nth-child(11) {
    top: 1790px;
    left: 100px;
  }
  .timeline-block:nth-child(12) {
    top: 1790px;
    left: 340px;
  }

  .timeline-block:nth-child(13) {
    top: 2038px;
    left: 100px;
  }
  .timeline {
    margin-top: 100px;
    padding-bottom: 1800px;
  }
}

@media (max-width: 519.98px) {
  .timeline-block:nth-child(1),
  .timeline-block:nth-child(3),
  .timeline-block:nth-child(5),
  .timeline-block:nth-child(7),
  .timeline-block:nth-child(9),
  .timeline-block:nth-child(11),
  .timeline-block:nth-child(13) {
    left: 14%;
  }

  .timeline-block:nth-child(2),
  .timeline-block:nth-child(4),
  .timeline-block:nth-child(6),
  .timeline-block:nth-child(8),
  .timeline-block:nth-child(10),
  .timeline-block:nth-child(12) {
    left: 64%;
  }
  .timeline-block span {
    bottom: -55px;
    height: 30px;
    width: 165%;
  }
}

@media (max-width: 479.98px) {
  .timeline h2 {
    font-size: 32px;
  }
  .timeline-block,
  .timeline-block:nth-child(2) {
    top: 510px;
  }
  .timeline-block:nth-child(3),
  .timeline-block:nth-child(4) {
  }

  .timeline-block:nth-child(5),
  .timeline-block:nth-child(6) {
    top: 975px;
  }

  .timeline-block:nth-child(7),
  .timeline-block:nth-child(8) {
    top: 1200px;
  }
  .timeline-block:nth-child(9),
  .timeline-block:nth-child(10) {
    top: 1440px;
  }

  .timeline-block:nth-child(11),
  .timeline-block:nth-child(12) {
    top: 1680px;
  }

  .timeline-block:nth-child(13) {
    top: 1915px;
  }
  .timeline {
    padding-bottom: 1720px;
  }
}

@media (max-width: 439.98px) {
  .timeline h2 {
    font-size: 30px;
  }
  .mobile-svg {
    top: 28vh;
  }
  .timeline-block {
    width: 90px;
    height: 90px;
  }
  .timeline-block::before {
    width: 90px;
    height: 90px;
  }
  .timeline-block,
  .timeline-block:nth-child(2) {
    top: 460px;
  }

  .timeline-block:nth-child(3),
  .timeline-block:nth-child(4) {
    top: 670px;
  }
  .timeline-block:nth-child(5),
  .timeline-block:nth-child(6) {
    top: 890px;
  }
  .timeline-block:nth-child(7),
  .timeline-block:nth-child(8) {
    top: 1095px;
  }

  .timeline-block:nth-child(9),
  .timeline-block:nth-child(10) {
    top: 1315px;
  }
  .timeline-block:nth-child(11),
  .timeline-block:nth-child(12) {
    top: 1530px;
  }
  .timeline-block:nth-child(13) {
    top: 1740px;
  }
  .timeline {
    padding-bottom: 1520px;
  }
}

@media (max-width: 399.98px) {
  .mobile-svg {
    top: 22vh;
  }
  .timeline-block,
  .timeline-block:nth-child(2) {
    top: 480px;
  }

  .timeline-block:nth-child(3),
  .timeline-block:nth-child(4) {
    top: 670px;
  }
  .timeline-block:nth-child(5),
  .timeline-block:nth-child(6) {
    top: 855px;
  }
  .timeline-block:nth-child(7),
  .timeline-block:nth-child(8) {
    top: 1050px;
  }

  .timeline-block:nth-child(9),
  .timeline-block:nth-child(10) {
    top: 1250px;
  }
  .timeline-block:nth-child(11),
  .timeline-block:nth-child(12) {
    top: 1440px;
  }
  .timeline-block:nth-child(13) {
    top: 1620px;
  }
  .timeline {
    padding-bottom: 1320px;
  }
}

@media (max-width: 359.98px) {
  .mobile-svg {
    top: 11vh;
  }
  .timeline-block,
  .timeline-block:nth-child(2) {
    top: 470px;
  }

  .timeline-block:nth-child(3),
  .timeline-block:nth-child(4) {
    top: 640px;
  }
  .timeline-block:nth-child(5),
  .timeline-block:nth-child(6) {
    top: 810px;
  }
  .timeline-block:nth-child(7),
  .timeline-block:nth-child(8) {
    top: 975px;
  }

  .timeline-block:nth-child(9),
  .timeline-block:nth-child(10) {
    top: 1140px;
  }
  .timeline-block:nth-child(11),
  .timeline-block:nth-child(12) {
    top: 1315px;
  }
  .timeline-block:nth-child(13) {
    top: 1477px;
  }
  .timeline {
    padding-bottom: 1180px;
  }
}
