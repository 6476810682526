.main-banking {
  margin-top: 170px;
  text-align: center;
}
.banking-imge {
  margin: auto;
}
.banking h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  text-transform: uppercase;
  color: #000000;
  padding: 30px;
  border: 5px solid #01aaec;
  display: inline-block;
  margin: 0 auto 80px;
  text-align: center;
}

.banking h2 span {
  color: #01aaec;
}

.banking-slide {
  background: #eaf3f8;
  box-shadow: -30px 35px 30px rgba(81, 94, 90, 0.26);
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  margin-left: 55px;
  width: 90%;
  height: 425px;
  transition: 0.2s;
  margin-bottom: 60px;
  z-index: 1;
  position: relative;
}

.banking-slide h6 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  margin: 20px auto;
  align-items: center;
  text-transform: uppercase;
  color: #000000;
  transition: 0.5s;
}

.banking-slide p {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  transition: 0.42s;
}

.banking-slide:hover {
  transition: 0.4s;
  box-shadow: -30px 35px 30px 0px #3b95ff42;
}
.banking-slide::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  left: 0;
  background: linear-gradient(193.26deg, #029ff6 2.86%, #054163 99.07%);
  z-index: -1;
  transition: opacity 0.5s linear;
  opacity: 0;
}

.banking-slide:hover::before {
  opacity: 1;
}

.banking-slide:hover p,
.banking-slide:hover h6 {
  transition: 0.4s;
  color: #fff;
}

.banking-slidesvg path {
  transition: 0.4s;
}

.banking-slide:hover svg path {
  transition: 0.4s;
  fill: #fff;
}

.banking .slick-dots li {
  margin: 0 10px;
}

.banking .slick-dots li button:before {
  font-size: 24px;
}

.banking .slick-dots li.slick-active button:before {
  color: #01aaec;
}

@media (max-width: 1199.98px) {
  .banking-slide {
    padding: 30px 10px;
    width: 82%;
    height: 450px;
  }
}

@media (max-width: 991.98px) {
  .banking h2 {
    font-size: 38px;
    line-height: 38px;
  }

  .banking-slide {
    height: auto;
  }
}

@media (max-width: 767.98px) {
  .banking h2 {
    font-size: 24px;
  }
  .main-banking {
    margin-top: 80px;
  }
}
@media (max-width: 575.98px) {
  .banking-slide {
    width: 80%;
  }
}
