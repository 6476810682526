.main-networkinfo {
  padding-top: 100px;
}

.main-networkinfo h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  display: block;
  margin: 0 auto;
  text-align: center;
  align-items: center;
  text-transform: uppercase;
  color: #202020;
  margin-bottom: 100px;
}

.main-networkinfo h2 span {
  color: #01aaec;
}

.top-networkinfo,
.bottom-networkinfo {
  display: flex;
  margin-top: 50px;
}

.main-networkinfo .left,
.main-networkinfo .right {
  width: 50%;
}

.main-networkinfo h4 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #202020;
  border: 5px solid #01aaec;
  padding: 20px 30px;
  display: inline-block;
  margin: 40px 0;
}
.main-networkinfo h4 span {
  color: #01aaec;
}

.main-networkinfo p {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 132.19%;
  text-align: right;
  color: #010101;
}

.main-networkinfo img {
  margin: 0 auto;
}

@media (max-width: 991.98px) {
  .top-networkinfo,
  .bottom-networkinfo {
    flex-wrap: wrap;
  }
  .bottom-networkinfo {
    flex-direction: column-reverse;
  }
  .main-networkinfo .left,
  .main-networkinfo .right {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 767.98px) {
  .main-networkinfo h2 {
    margin-bottom: 0;
    font-size: 36px;
    line-height: 36px;
  }

  .main-networkinfo h4 {
    font-size: 36px;
    line-height: 36px;
  }

  .main-networkinfo p {
    font-size: 22px;
    line-height: 132.19%;
  }
}
