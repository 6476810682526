.networkmain {
  background: url("../../../img/community-bg.png") no-repeat center;
}

.main-networkmain {
  padding-top: 50px;
  padding-bottom: 100px;
}
.networkmain .downloads-buttons {
  margin-top: 0;
}

.main-networkmain .downloads-buttons {
  justify-content: center;
}

.main-networkmain .downloads-buttons a {
  max-width: 100px;
  margin: 0 20px;
}

.main-networkmain h1 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 64px;
  text-align: center;
  color: #ffffff;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 120px;
}

.icons {
  display: flex;
  justify-content: space-between;
  margin-top: 120px;
}

.icons div {
  text-align: center;
}

.icons div img {
  margin: 0 auto 20px;
}

.icons div a {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: 550;
  font-size: 24px;
  line-height: 132.19%;
  color: #ffffff;
  transition: 0.5s;
  display: block;
}

.icons div a:hover {
  transition: 0.5s;
  transform: scale(1.1);
}

@media (max-width: 991.98px) {
  .main-networkmain .downloads-buttons {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .main-networkmain h1 {
    font-size: 36px;
    line-height: 36px;
    margin-top: 0;
  }
  .icons {
    flex-wrap: wrap;
    margin-top: 50px;
  }

  .icons div a {
    font-family: HelveticaNeueCyr;
    font-style: normal;
    font-weight: 550;
    font-size: 20px;
    line-height: 132.19%;
  }

  .icons div {
    width: 45%;
    margin-bottom: 20px;
  }
}
