.wallet-header .header-links {
  display: none !important;
}

.mobile-wallet ~ footer {
  display: none;
}

.mobile-wallet {
  margin-top: -76px;
  padding-top: 76px;
  min-height: 100vh;
  background: url("/src/img/community-bg.png") no-repeat center;
  background-size: cover;
  overflow: hidden;
}

.mobile-wallet-left {
  width: 50%;
}

.mobile-wallet-right {
  width: 50%;
  padding-left: 30px;
}

.main-mobile-wallet {
  display: flex;
  position: relative;
}

.wallet-slide h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  color: #088dc6;
}

.wallet-slide span {
  font-family: HelveticaNeueCyr;
  display: block;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 22px;
  color: #ffffff;
  margin: 30px 0;
}

.first-mobile img {
  position: absolute;
  margin-top: 80px;
  height: 370px;
  transition: 0.5s;
  left: 0%;
}

.second-mobile img {
  position: absolute;
  margin-top: 0px;
  height: 370px;
  transition: 0.5s;
  left: 25%;
}

.active-img img {
  transition: 0.5s;
  position: absolute;
  margin-top: 210px;
  left: 69%;
  height: 580px;
}
.wallet-downloads {
  display: flex;
  margin-top: -10px;
}

.wallet-downloads a {
  border: 1px solid #a6a6a6;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.4s;
  width: 130px;
  margin-right: 20px;
}

.wallet-downloads a:hover {
  box-shadow: 0px 0px 14px 0px #ffffffc2;
  transition: 0.4s;
}

.wallet-mobile-links {
  display: flex;
  width: 150px;
  justify-content: space-between;
  position: absolute;
  bottom: -80px;
  left: 25%;
  transform: translateX(-50%);
}

.wallet-mobile-links svg path {
  transition: 0.4s;
}

.wallet-mobile-links a:hover svg path {
  fill: #101010;
  transition: 0.4s;
}

@media (max-width: 991.98px) {
  .first-mobile img,
  .second-mobile img {
    height: 300px;
  }
  .wallet-slide h2 {
    font-size: 32px;
  }
  .wallet-slide span {
    font-size: 18px;
  }
  .active-img img {
    margin-top: 253px;
    left: 59%;
  }
}

@media (max-width: 767.98px) {
  .mobile-wallet-left,
  .mobile-wallet-right {
    width: 100%;
    padding: 0;
    text-align: center;
  }
  .wallet-slide h2 {
    color: #ffffff;
    margin-top: 10px;
  }

  .mobile-wallet {
    flex-direction: column-reverse;
  }
  .wallet-downloads {
    width: 100%;
    justify-content: space-around;
  }
  .active-img img {
    margin-top: 253px;
    left: 50%;
    transform: translateX(-50%);
  }
  .first-mobile img,
  .second-mobile img {
    bottom: -999px;
  }
  .wallet-downloads a {
    margin: 0;
  }

  .wallet-mobile-links {
    display: none;
  }
}

@media (max-width: 489.98px) {
  .main-header-title h1 {
    font-size: 30px;
  }
}
