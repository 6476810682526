.mail {
  background: #e3e3e3;
}

.main-mail {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  align-items: center;
}

.main-mail div {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #00658c;
}

.main-mail div span {
  font-weight: bold;
}

.main-mail input {
  background: #ffffff;
  border: 1px solid #01aaec;
  box-sizing: border-box;
  padding: 15px;
  outline: navy;
}

.main-mail button {
  margin: 0 30px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 19px 40px;
  text-transform: uppercase;
  color: #ffffff;
  background: #01aaec;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: 0.4s;
}

.main-mail button:hover {
  transition: 0.4s;
  color: #171c20;
}

@media (max-width: 991.98px) {
  .main-mail input {
    width: 100%;
    margin-bottom: 20px;
  }

  .main-mail button {
    width: 100%;
    margin: 0;
  }
}

@media (max-width: 767.98px) {
  .main-mail {
    display: flex;
    flex-direction: column;
  }
  .main-mail div {
    margin-bottom: 20px;
  }
}
