.main-header-contact {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
}

.contact-block {
  width: 48%;
  background: #eaf3f8;
  box-shadow: -30px 35px 30px rgba(81, 94, 90, 0.26);
  border-radius: 10px;
  padding: 40px 20px;
  display: flex;
  justify-content: space-around;
  transition: 0.5s;
  z-index: 1;
  position: relative;
}

.contact-block::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  left: 0;
  background: linear-gradient(193.26deg, #029ff6 2.86%, #054163 99.07%);
  z-index: -1;
  transition: opacity 0.5s linear;
  opacity: 0;
}

.contact-block:hover::before {
  opacity: 1;
}

.contact-block h5 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #000000;
  transition: 0.5s;
}

.contact-block:hover h5,
.contact-block:hover a {
  transition: 0.5s;
  color: #fff;
}
.contact-block svg path {
  transition: 0.5s;
}

.contact-block:hover svg path {
  transition: 0.5s;
  fill: #fff;
}
.contact-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.contact-text a {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  color: #01aaec;
  transition: 0.5s;
}

@media (max-width: 991.98px) {
  .contact-block h5 {
    font-size: 36px;
  }
  .contact-text {
    margin-right: 10px;
  }
}

@media (max-width: 767.98px) {
  .contact-block {
    margin: 20px 0;
    width: 100%;
  }
  .main-header-contact {
    flex-wrap: wrap;
  }
  .right-form {
    width: 100%;
    margin-top: 30px;
    padding: 0;
  }
  .left-form {
    width: 100%;
  }
  .main-form {
    flex-wrap: wrap;
  }
}
@media (max-width: 399.98px) {
  .contact-block h5 {
    font-size: 27px;
  }

  .contact-block a {
    font-size: 20px;
  }
}
