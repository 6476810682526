.ankerplugin {
  padding: 100px 0 200px;
  position: relative;
}

.main-ankerplugin {
  width: 90%;
  border: 5px solid #01aaec;
  padding: 40px 40px 40px 45%;
  margin-left: 10%;
}

.main-ankerplugin h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 64px;
  color: #000000;
  margin: 0 0 40px;
}

.main-ankerplugin h2 span {
  color: #01aaec;
}

.main-ankerplugin p {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #010101;
}

.ankerplugin-order {
  display: inline-block;
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-95%);
}

.ankerplugin-order img {
  box-shadow: -30px 35px 30px 0px #00000040;
}
.ankerplugin a {
  text-align: left;
  display: block;
  margin: 30px auto;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 10px 30px;
  text-transform: uppercase;
  color: #ffffff;
  background: #01aaec;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: 0.4s;
  transform: translateX(200px);
  max-width: 180px;
}

.ankerplugin a:hover {
  transition: 0.4s;
  color: #171c20;
}

@media (max-width: 991.98px) {
  .main-ankerplugin {
    width: 100%;
    margin: 0 auto;
    padding: 40px 40px 120px;
  }
  .ankerplugin a {
    margin: 310px auto 0;
    transform: translateX(0);
  }
  .ankerplugin-order {
    top: 345px;
    transform: translateX(-50%);
    width: 100%;
  }
  .ankerplugin-order img {
    margin: 0 auto;
  }
}

@media (max-width: 767.98px) {
  .ankerplugin-order {
    top: 310px;
  }

  .ankerplugin-order img {
    max-width: 80%;
  }
  .ankerplugin {
    padding: 0 0 100px;
  }
}

@media (max-width: 575.98px) {
  .main-ankerplugin h2 {
    font-size: 48px;
    margin: 0 0 20px;
    text-align: center;
  }
  .ankerplugin a {
    margin: 210px auto 0;
  }
}
@media (max-width: 419.98px) {
  .main-ankerplugin {
    width: 100%;
    margin: 0 auto;
    padding: 40px 0 120px;
  }
  .main-ankerplugin p {
    display: block;
    padding: 0 20px;
  }
  .ankerplugin a {
    margin: 120px auto 0;
  }

  .main-ankerplugin h2 {
    font-size: 44px;
  }
  .main-ankerplugin p {
    font-size: 18px;
  }
}
