.partners {
  margin-top: 120px;
}

.partners h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  color: #01aaec;
  margin-bottom: 50px;
}

.partners h2 span {
  color: #202020;
}

.main-partners {
  margin-bottom: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.partners-block {
  width: 22%;
  background: #f0f5f6;
  box-shadow: -30px 35px 30px rgba(81, 94, 90, 0.26);
  border-radius: 0px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  transition: 0.5s;
}

.partners-block:hover {
  transition: 0.5s;
  transform: scale(1.1);
}

@media (max-width: 991.98px) {
  .partners-block {
    width: 30%;
  }
}

@media (max-width: 767.98px) {
  .partners-block {
    width: 45%;
  }
  .partners-block img {
    max-width: 90%;
    margin: 0 auto;
  }
}
