footer {
  background-color: #111a20;
  padding-bottom: 50px;
}

.top-footer {
  padding-top: 40px;
  display: flex;
}

.left-footer {
  margin-top: 20px;
  width: 40%;
}

.left-footer span {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #ffffff;
  display: block;
  margin: 50px 0;
}

.center-footer {
  width: 40%;
}

.center-footer h6,
.right-footer h6 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: #01aaec;
  text-transform: uppercase;
  margin-bottom: 50px;
}

.center-footer li {
  list-style: none;
}

.center-footer li a {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: block;
  line-height: 18px;
  color: #ffffff;
  margin: 20px 0;
}

.right-footer {
  padding-left: 70px;
}
.right-footer a {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #ffffff;
  margin-top: 40px;
  display: block;
}

.right-footer span {
  margin-top: 80px;
  display: block;
}

.right-footer span a {
  color: #01aaec;
}

footer .downloads-buttons {
  margin-top: 30px;
  margin-bottom: 20px;
}
footer .bottom-footer .menu li a:hover,
footer .bottom-footer .menu li a.active {
  color: #01aaec;
  transition: 0.5s;
}

@media (max-width: 767.98px) {
  footer .menu ul {
    display: none;
  }

  .top-footer {
    flex-wrap: wrap;
  }
  .left-footer,
  .center-footer,
  .right-footer {
    width: 100%;
    text-align: center;
  }

  .left-footer a {
    display: flex;
    justify-content: center;
  }

  .right-footer {
    margin: 40px 0;
    padding: 0;
  }
}
