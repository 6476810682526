.center-mainankenode {
  display: flex;
  margin-top: 100px;
}

.right-mainanknode {
  width: 58%;
}

.left-mainanknode {
  width: 42%;
  padding-right: 10px;
}

.left-mainanknode h4 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #202020;
  display: inline-block;
  padding: 10px 20px;
  border: 5px solid #01aaec;
  margin: 50px 0;
}

.left-mainanknode h4 span {
  color: #01aaec;
}

.left-mainanknode p {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  color: #010101;
}

@media (max-width: 991.98px) {
  .center-mainankenode {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .right-mainanknode {
    width: 100%;
  }
  .left-mainanknode {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .center-mainankenode {
    display: flex;
    margin-top: 0px;
  }
  .left-mainanknode h4 {
    margin: 0;
  }
  .left-mainanknode p {
    margin-top: 20px;
  }

  .right-mainanknode svg {
    margin: 40px 0;
  }
}

@media (max-width: 399.98px) {
  .left-mainanknode h4 {
    font-size: 30px;
  }
}
