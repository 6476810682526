.schema {
  margin-top: 180px;
  margin-bottom: 150px;
  height: auto;
  overflow: auto;
}

.schema::-webkit-scrollbar {
  width: 0;
}

.schema-title {
  margin: 120px 0;
  min-width: 1258px;
  display: flex;
  justify-content: space-between;
}

.schema-title div {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #000000;
  display: flex;
  align-items: center;
}

.schema-title div span {
  color: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #01aaec;
  margin-right: 15px;
}

.schema-title div:nth-child(2) span {
  background-color: #959292;
}

.schema-title div:nth-child(3) span {
  background-color: #0114C2;
}

.schema-svg {
  margin-top: 80px;
  position: relative;
}

.schema-block {
  position: absolute;
  width: 114px;
  height: 114px;
  display: flex;
  top: 10px;
  left: 10px;
  justify-content: center;
  align-items: center;
  background: #eaf3f8;
  box-shadow: -15px 18px 30px rgba(81, 94, 90, 0.26);
  border-radius: 10px;
}

.schema-block span {
  position: absolute;
  top: -40px;
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  white-space: nowrap;
}

.schema-block:nth-child(2) {
  left: 350px;
}

.schema-block:nth-child(3) {
  left: 600px;
}

.schema-block:nth-child(4) {
  left: 838px;
}

.schema-block:nth-child(5) {
  left: 1158px;
}

.schema-block:nth-child(6) {
  left: 603px;
  top: 301px;
}

.schema-block:nth-child(6) span {
  top: 140px;
}

.schema-svg {
  width: 1258px;
  margin: 0 auto;
  height: auto;
}

@media (max-width: 991.98px) {
  .schema {
    margin-top: 0;
  }
}
