.community-header {
  background: url("../../../img/community-bg.png") no-repeat center;
  background-size: cover;
  margin-top: -76px;
  padding-bottom: 100px;
}

.main-community-header h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 64px;
  color: #161c20;
  padding-top: 250px;

  text-align: center;
  margin-left: 60px;
}

.main-community-header h2 span {
  color: #01aaec;
}

.main-community-header h6 {
  margin-top: 70px;
  text-align: center;
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 36px;
  color: #ffffff;
}

.main-community-header button {
  display: block;
  margin: 100px auto 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 10px 30px;
  text-transform: uppercase;
  color: #ffffff;
  background: #01aaec;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: 0.4s;
}

.main-community-header button:hover {
  transition: 0.4s;
  color: #171c20;
}

@media (max-width: 991.98px) {
  .main-community-header h2 {
    font-size: 62px;
  }
}

@media (max-width: 767.98px) {
  .main-community-header h2 {
    font-size: 46px;
    margin-left: 43px;
  }
  .main-community-header h6 {
    font-size: 30px;
  }
}

@media (max-width: 575.98px) {
  .main-community-header h2 {
    font-size: 32px;
    margin-left: 31px;
  }
  .main-community-header h6 {
    font-size: 24px;
  }
}

@media (max-width: 399.98px) {
  .main-community-header h2 {
    font-size: 25px;
    margin-left: 25px;
    padding-top: 180px;
  }
  .main-community-header h6 {
    font-size: 20px;
  }
}
