.videos {
  padding-top: 100px;
}
.videos h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  color: #121a20;
}

.videos h2 span {
  color: #01aaec;
}

.video-block h4 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 30px;
}

.video-block h4 span {
  color: #01aaec;
}

.main-videos {
  margin-top: 50px;
  display: flex;
}

.video-block {
  padding: 20px;
  width: 50%;
}

.video-block video {
  width: 100%;
  height: auto;
  box-shadow: -15px 30px 30px 0px #00000040;
}

@media (max-width: 767.98px) {
  .main-videos {
    flex-wrap: wrap;
  }
  .video-block {
    width: 100%;
  }

  .videos h2 {
    font-size: 36px;
    line-height: 36px;
  }

  .main-videos h4 {
    font-size: 24px;
    line-height: 24px;
  }
}
