.main-form {
  display: flex;
  margin-top: 130px;
  margin-bottom: 100px;
}
.left-form {
  width: 42%;
}

.right-form {
  width: 58%;
}

.left-form h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 64px;
  text-transform: uppercase;
  color: #000000;
  border: 5px solid #01aaec;
  padding: 20px;
  text-align: right;
}
.left-form h2 span {
  color: #01aaec;
}

.left-form h6 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  color: #000000;
  margin: 30px 0;
  text-align: right;
}
.right-form {
  padding-left: 40px;
}

.right-form form {
  background: #eaf3f8;
  box-shadow: -30px 35px 30px rgba(81, 94, 90, 0.26);
  border-radius: 10px;
  padding: 20px;
}

.right-form form input {
  width: 100%;
  margin: 15px auto;

  padding: 10px;
  outline: none;
  font-size: 22px;
  line-height: 24px;
  font-family: HelveticaNeueCyr;
  font-weight: normal;
}

.right-form form textarea {
  width: 100%;
  margin: 15px auto;
  outline: none;
  padding: 10px;
  font-size: 22px;
  line-height: 24px;
  font-family: HelveticaNeueCyr;
  font-weight: normal;
}
.right-form form input:focus,
.right-form form textarea:focus {
  transition: 0.5s;
  border: 2px solid #01aaec;
}

.right-form form textarea::-webkit-input-placeholder,
.right-form form input::-webkit-input-placeholder {
  font-family: HelveticaNeueCyr;
  font-style: italic;
  font-size: 22px;
  line-height: 24px;
  color: #817b7b;
}
.right-form form textarea::-moz-placeholder,
.right-form form input::-webkit-input-placeholder {
  font-family: HelveticaNeueCyr;
  font-style: italic;
  font-size: 22px;
  line-height: 24px;
  color: #817b7b;
}
.right-form form textarea:-moz-placeholder,
.right-form form input:-moz-placeholder {
  font-family: HelveticaNeueCyr;
  font-style: italic;
  font-size: 22px;
  line-height: 24px;
  color: #817b7b;
}
.right-form form textarea:-ms-input-placeholder,
.right-form form input:-ms-input-placeholder {
  font-family: HelveticaNeueCyr;
  font-style: italic;
  font-size: 22px;
  line-height: 24px;
  color: #817b7b;
}

.right-form button {
  display: block;
  margin: 70px auto;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 10px 30px;
  text-transform: uppercase;
  color: #ffffff;
  background: #01aaec;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: 0.4s;
}

.right-form button:hover {
  transition: 0.4s;
  color: #171c20;
}

@media (max-width: 399.98px) {
  .left-form h2 {
    font-size: 48px;
    line-height: 48px;
  }
  .left-form h6 {
    font-size: 16px;
  }
}
