.header-soc-link {
  display: flex;
  align-items: center;
}

.header-soc-link a {
  margin: 0 5px;
}

.header-soc-link a svg {
  width: 25px;
  height: 25px;
}

.header-soc-link a svg path {
  transition: 0.5s;
}

.header-soc-link a:hover svg path {
  transition: 0.5s;
  fill: #111;
}

@media (max-width: 991.98px) {
  .header-soc-link {
    display: none;
  }
}
