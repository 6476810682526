.column {
  display: flex;
  margin: 20px;
  margin-top: 70px;
}

.column .text,
.column .img {
  width: 50%;
  text-align: right;
}
.column .text {
  padding-right: 30px;
}

.column:first-child :first-child h3::before {
  content: "Android / PC";
  position: absolute;
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #01aaec;
  left: 0;
  top: -32px;
}

.column:nth-child(2) .text {
  text-align: left;
  padding-left: 30px;
}
.column:first-child {
  margin-top: 100px;
}
.column:first-child .text {
  width: 40%;
}
.column:first-child .img {
  width: 60%;
}

.column .img img {
  margin: 0 auto;
}
.column h3 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 64px;
  color: #171c20;
  border: 5px solid #01aaec;
  padding: 20px 30px;
  display: inline-block;
  position: relative;
  margin: 20px 0;
}

.column h3 span {
  color: #01aaec;
}

.column p {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 28px;
  color: #010101;
}

.collapse {
  border-left: 10px solid #01aaec;
  padding: 20px;
  background: #eaf3f8;
  box-shadow: -30px 35px 30px rgba(81, 94, 90, 0.26);
  border-radius: 4px;
  text-align: left;
  display: inline-block;
  width: 400px;
  margin: 20px 0;
}

.collapse .collapse-title {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: 550;
  font-size: 24px;
  line-height: 26px;
  color: #000000;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  transition: 0.4s;
  padding: 10px;
}
.collapse .collapse-title.active {
  transition: 0.4s;
  color: #01aaec;
}

.collapse .collapse-title i {
  transition: 0.4s;
  color: #01aaec;
}

.collapse .collapse-title.active i {
  transition: 0.4s;
  transform: rotate(90deg);
}

.collapse .collapse-text {
  padding: 20px 10px;
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #010101;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.column a {
  display: block;
  margin: 30px auto;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 10px 30px;
  text-transform: uppercase;
  color: #ffffff;
  background: #01aaec;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: 0.4s;
  text-align: center;
  max-width: 170px;
}

.column a:hover {
  transition: 0.4s;
  color: #171c20;
}

@media (max-width: 991.98px) {
  .column {
    flex-wrap: wrap;
    margin: 20 0;
  }
  .column .text,
  .column .img,
  .column:first-child .img,
  .column:first-child .text {
    width: 100%;
    text-align: left;
  }

  .column:nth-child(2) {
    flex-direction: column-reverse;
  }
  .column p {
    margin: 20px 0;
    padding: 0;
  }
  .column:nth-child(3) .img {
    margin-top: 40px;
  }
  .column .text,
  .column:nth-child(2) .text {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 470.98px) {
  .column h3 {
    font-size: 48px;
    padding: 10px;
  }
  .collapse {
    width: 100%;
  }
}
@media (max-width: 575.98px) {
  .collapse {
    padding: 10px 20px 10px;
  }
  .collapse .collapse-title {
    font-size: 20px;
  }
}

@media (max-width: 379.98px) {
  .column h3 {
    font-size: 42px;
  }
}
