.main-ankenode-slider {
  text-align: center;
  padding: 50px 0;
}

.main-ankenode-slider h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #000000;
  display: inline-block;
  padding: 10px 20px;
  border: 5px solid #01aaec;
  text-align: center;
  margin: 0 auto;
}

.main-ankenode-slider h2 span {
  color: #01aaec;
}

.ankedone-slide {
  background: #eaf3f8;
  box-shadow: -10px 15px 10px rgba(81, 94, 90, 0.26);
  border-radius: 10px;
  padding: 20px 10px;
  margin: 55px auto;
  width: 80%;
  height: 380px;
  position: relative;
  z-index: 1;
}

.ankedone-slide h6 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: #161616;
  margin: 30px 0;
  padding: 0 10px;
}

.ankedone-slide p {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #010101;
}

.ankedone-slide ::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  left: 0;
  background: linear-gradient(193.26deg, #029ff6 2.86%, #054163 99.07%);
  z-index: -1;
  transition: opacity 0.5s linear;
  opacity: 0;
}

.ankedone-slide :hover::before {
  opacity: 1;
}

.ankedone-slide:hover h6,
.ankedone-slide:hover p {
  transition: 0.5s;
  color: #fff;
}

.ankedone-slide svg path {
  transition: 0.5s;
}

.ankedone-slide:hover svg path {
  transition: 0.5s;
  fill: #fff;
}
.main-ankenode-slider .slick-dots li button:before {
  font-size: 22px;
}

.main-ankenode-slider .slick-dots li.slick-active button:before {
  color: #01aaec;
}
