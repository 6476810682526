.wallet {
  padding-bottom: 50px;
}
.main-wallet {
  display: flex;
}

.left-wallet,
.right-wallet {
  width: 50%;
}

.main-wallet h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 90px;
  color: #000000;
}

.main-wallet h2 span {
  border: 5px solid #01aaec;
  padding: 10px;
  color: #01aaec;
}

.left-wallet {
  text-align: right;
  padding-right: 30px;
}

.left-wallet h6 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 38px;
  color: #010101;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 60px;
  margin-bottom: 20px;
}

.left-wallet p {
  display: block;
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #01aaec;
  margin-top: 10px;
}

.wallet button {
  display: block;
  margin: 70px auto;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 10px 30px;
  text-transform: uppercase;
  color: #ffffff;
  background: #01aaec;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: 0.4s;
}

.wallet button:hover {
  transition: 0.4s;
  color: #171c20;
}

@media (max-width: 991.98px) {
  .main-wallet {
    flex-wrap: wrap;
  }

  .left-wallet,
  .right-wallet {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .main-wallet h2 {
    font-size: 48px;
  }
}
