.top-header {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}

.top-menu ul {
  display: flex;
  justify-content: space-around;
  height: 100%;
  align-items: center;
}

.top-menu ul li a {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #000000;
  margin: 0px 10px;
  transition: 0.4s;
}

.top-menu a.active {
  color: #01aaec;
}

.main-header {
  background: url("../../img/hd-bg.png") no-repeat center;
  background-size: cover;
}

.main-header-title {
  display: flex;
  padding: 20px 0;
}
.main-header-title h1 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  color: #ffffff;
}

.header-title {
  width: 50%;
}

.header-links {
  width: 50%;
  display: flex;
  justify-content: space-around;
  padding-left: 20px;
}

.header-links a {
  border: 1px solid #a6a6a6;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.4s;
  max-width: 21%;
  height: fit-content;
}

.header-links a:hover {
  box-shadow: 0px 0px 14px 0px #ffffffc2;
  transition: 0.4s;
}

.top-menu a:hover {
  color: #01aaec;
  transition: 0.4s;
}

@media (max-width: 991.98px) {
  .top-menu {
    display: none;
  }
  .header-links {
    display: none;
  }
  header .downloads-buttons {
    margin-top: 50px;
  }

  header .downloads-buttons a {
    max-width: 178px;
  }
}

@media (max-width: 379.98px) {
  .main-header-title h1 {
    font-size: 28px;
  }
}
