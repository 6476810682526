.system {
  padding-top: 100px;
}

.system h2 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  margin: 0 auto;

  text-align: center;
  text-transform: uppercase;
  color: #121a20;
}
.system h2 span {
  color: #01aaec;
}

.main-system {
  display: flex;
}

.main-system .left,
.main-system .right {
  width: 50%;
  padding: 0 20px;
}

.main-system .right {
  display: flex;
  align-items: center;
}

.main-system h4 {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #202020;
  border: 5px solid #01aaec;
  padding: 20px 30px;
  margin: 40px 0;
}

.main-system h4 span {
  color: #01aaec;
}

.main-system p {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 132.19%;
  text-align: right;
  color: #010101;
}

.main-system a {
  font-family: HelveticaNeueCyr;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 132.19%;
  text-align: right;
  color: #01aaec;
  display: block;
  text-decoration: underline;
  margin-top: 50px;
}

@media (max-width: 991.98px) {
  .main-system {
    flex-wrap: wrap;
  }
  .main-system .left,
  .main-system .right {
    width: 100%;
  }
  .main-system .right img {
    margin: 20px auto;
  }
}

@media (max-width: 767.98px) {
  .system h2 {
    font-size: 36px;
    line-height: 36px;
  }

  .system h4 {
    font-size: 36px;
    line-height: 36px;
  }
  .main-system p {
    font-size: 22px;
  }
}
